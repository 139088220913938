export function chainMap (id) {
	
	  if (id === "0x89") return "Matic"
	  if (id === "0xfa") return "Fantom"
	  if (id === "0xfa2") return "FantomTEST"
	  if (id === "0x38") return "BSC"
	  if (id === "0x1") return "Ethereum"
	  if (id === "0x64") return "xDai"
	  return null
}

export function enforceChain(chainName, callback = () => {}) {
	(chainMap(window.ethereum.chainId) === chainName) ? callback() : alert("Please switch to " + chainName)
}

export function enforceChains(chainNames, callback = () => {}) {

	(chainNames.includes(chainMap(window.ethereum.chainId))) ? callback() : alert("Please switch to " + chainNames)
}