import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain, enforceChains } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'


import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'



import { FarmgodCore, 
       basicItems, standardTokens, 
       contractConfigs, testItems, 
       testTokens, testCitizens, liveCitizens } from './deliJS/farmgod-core.js'





export function Dapp() {
  

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  const [activeChainId, setActiveChainId] = React.useState("")
  function handleChainChange(chainId) {
    console.log("handling chain change")
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));

    enforceChains(["Fantom","FantomTEST"], letItRip)
    setActiveChainId(chainId)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');


const letItRip = () => {

}   

 const [isPending, setIsPending] = React.useState(false);
  const [stablecoin, setStablecoin] = React.useState("")
  const [citizenAddress, setCitizenAddress] = React.useState("")
  const [citizenship, setCitizenshipAddress] = React.useState("")
  
  React.useEffect(()=>{
    if (connected) {
      if (activeChainId == "0xfa2") {

      //setStablecoin(testTokens["dai"]["address"])
      //setCitizenAddress(testCitizens["citizen"]["address"])
      //setCitizenshipAddress(testCitizens["citizenship"]["address"])
    } else {

      setStablecoin(standardTokens["dai"]["address"])
     setCitizenAddress(liveCitizens["citizen"]["address"])
      setCitizenshipAddress(liveCitizens["citizenship"]["address"])
    }
    }
    
  }, [activeChainId])

  const [userBal, setUserBal] = React.useState(0)

  const getUserBalance = () => {
    var citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
    citizen.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
      .then((res)=> {
        setUserBal(res)
        console.log(res)
      })
  }

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [activeCitizen, setActiveCitizen] = React.useState(999999)
  const [activeCitizenImage, setActiveCitizenImage] = React.useState("")
  const [isCitizen, setIsCitizen] = React.useState(false)

  const getCitizenId = () => {
    var citizen;
  if (window.ethereum.selectedAddress == null) {

  } else {
    citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
    citizen.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, 0).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setActiveCitizen(res)
    }).catch((err)=>{
      
    })
}
  }
  
  const setImage = () => {
    var citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
    citizen.methods.tokenURI(activeCitizen).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      let x = atob(res.replace(/^data:application\/(json);base64,/, ''));
      x = JSON.parse(x)
      console.log(x)
      x = x.image
      console.log(x)
      setActiveCitizenImage(x)
    })
  }

 

  
  const mintCitizen = () => {
    var citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
    setIsPending(true)
    citizen.methods.mint().send({from: window.ethereum.selectedAddress, value: "10000000000000000"})
    .then((res) => {
      setIsPending(false)
      setUserBal(1)
      setIsCitizen(false)
    }).catch((err)=>{
      setIsPending(false)
      alert(err)
    })
  }

  const registerCitizenship = (region, loc) => {
    return () => {
      var citizenship = new web3.eth.Contract(
        liveCitizens["citizenship"]["abi"], 
        liveCitizens["citizenship"]["address"]);
      setIsPending(true)
      citizenship.methods.registerCitizenship(
        activeCitizen,
        region,
        loc
        ).send({from: window.ethereum.selectedAddress})
    .then((res) => {
      setIsPending(false)
      setImage()
      setIsCitizen(true)
    }).catch((err)=>{
      setIsPending(false)
      alert(err)
    })

    }
  }

  const nameInput = React.useRef("");

  const changeName = () => {
    var citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
    setIsPending(true)
    citizen.methods.setName(activeCitizen, nameInput.current.value).send({from: window.ethereum.selectedAddress})
    .then((res) => {
      setIsPending(false)
      setImage()
    })
  }

  const transferToNPC = () => {
    var citizen = new web3.eth.Contract(liveCitizens["citizen"]["abi"], liveCitizens["citizen"]["address"]);
    setIsPending(true)
    citizen.methods.transferToNPCs(activeCitizen).send({from: window.ethereum.selectedAddress})
    .then((res) => {
      setIsPending(false)
      setUserBal(0)
      setActiveCitizen(999999)
    }).catch((err)=>{
      setIsPending(false)
    })
  }

  const getIsCitizen = () => {
    var citizenship = new web3.eth.Contract(
        liveCitizens["citizenship"]["abi"], 
        liveCitizens["citizenship"]["address"]);
      citizenship.methods.getLastClaim(
        activeCitizen
        ).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      console.log(res)
      if ( res == 0) {
        setIsCitizen(false)
      } else {
        setIsCitizen(true)
      }
      
    })
  }

  const refreshImage = () => {
    if (userBal == 1) {
      setImage()
    } else {
      getUserBalance()
    }
    
  }

React.useEffect(()=>{
    if (citizenAddress !== "") {
      getUserBalance()
    }
    
  }, [citizenAddress])

  React.useEffect(()=>{
    if (userBal > 0 && activeCitizen == 999999) {
      console.log("activeCitizen",activeCitizen)
      getCitizenId()
    }
    
  }, [userBal])
   React.useEffect(()=>{
    if (activeCitizen !== 999999) {
      setImage()
      getIsCitizen()
    }
  }, [activeCitizen])

  return (
    <div className={"App-wrapper " + " connected--" + connected}>

   
      <button disabled={isDisabled} onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
      
      <div className={"citizen-container connected--" + connected}>
        <div className={"active-citizen bal--" +userBal}>
          <img src={activeCitizenImage} />
        </div>
        <div className="active-citizen-controls">
          <div className={"active-citizenship-controls bal--" + userBal + " citizen--" + isCitizen}>
            <h3>Citizenship</h3>
            <p>Citizenship can only be changed once every 13 weeks.</p>
            <button onClick={registerCitizenship(0,0)}>Register to City of Lauren</button>
            <button onClick={registerCitizenship(0,1)}>Register to Terrara</button>
            <button onClick={registerCitizenship(1,0)}>Register to Shio City</button>
            <button onClick={registerCitizenship(1,1)}>Register to Toray</button>
            <button onClick={registerCitizenship(2,0)}>Register to Nibo</button>
            <button onClick={registerCitizenship(2,1)}>Register to Arbo</button>
            <button onClick={registerCitizenship(3,0)}>Register to Vita</button>
          </div>
          <div className={"name-change bal--" + userBal}>
            <h3>Name Change</h3>
            <p>The first name change for each Citizen is free.</p>
            <input ref={nameInput} />
            <button onClick={changeName}>change name</button>
          </div>
          <div className={"npc bal--" + userBal}>
            <h3>NPCs</h3>
            <p>Turning a Citizen into an NPC transfers it to the NPC wallet. You will forfeit ownership, but earn 10% of the annual UBI.</p>
            <p>Do this if you want to mint a new citizen.</p>
            <p>Make sure you registered and named the Citizen before transfer.</p>
            <button onClick={transferToNPC}>Turn into NPC</button>
          </div>
        </div> 
        <div className={"no-citizen-controls bal--" + userBal}>
          <h3>Mint a Citizen</h3>
          <p>We do not detect a Citizen NFT in your wallet. Mint a new citizen for 0.01 FTM.</p>
          <button onClick={mintCitizen}>mint</button>
        </div>
        <button className="refresh" onClick={refreshImage}>Refresh URI</button>
        <div className={"pending pending--" + isPending}>Pending</div>
      </div>

   
    </div>

  );
}

