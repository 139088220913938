
import './App.scss';
//import { Dapp } from './multiverse.js';
import { Dapp } from './testcitizens.js';

function App() {
  return (
    <div className="App">
      <Dapp />
    </div>
  );
}

export default App;
